import React from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout";
import TopHeader from "../../components/top_header";
import Footer from "../../components/footer";

import "../../styles/asite.scss";

import HeaderLogoImage from "../../images/common/header_logo@2x.png";

export default function About(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  var termUrl,
    sonyGedUrl = "";
  switch (currentLanguage) {
    case "ja":
      termUrl = "https://www.sonyged.com/ja/terms-and-conditions/";
      sonyGedUrl = "https://www.sonyged.com/ja/";
      break;
    case "zh":
      termUrl = "https://www.sonyged.com/zh-hans/terms-and-conditions/";
      sonyGedUrl = "https://www.sonyged.com/zh-hans/";
      break;
    default:
      termUrl = "https://www.sonyged.com/terms-and-conditions/";
      sonyGedUrl = "https://www.sonyged.com/en";
      break;
  }

  return (
    <Layout>
      <div className="asite">
        <TopHeader headerLogoImage={HeaderLogoImage} />

        <div className="content_full_box">
          <div className="content_box">
            <div className="text_column" id="this_site">
              <div className="inner">
                <div className="title">{t("layouts.titles.about_site")}</div>
                <div
                  className={`description dynamic_line_height ${
                    currentLanguage === "en" ? "line_height_narrow" : ""
                  }`}
                >
                  {t("mmind.asite.description1")}
                  <a
                    className="link_gray_dotted"
                    target="_blank"
                    rel="noreferrer"
                    href={sonyGedUrl}
                  >
                    {t("mmind.asite.link_text1")}
                  </a>
                  {t("mmind.asite.description2")}
                </div>
              </div>
            </div>
            <div className="text_column" id="link_site">
              <div className="inner">
                <div className="title">{t("mmind.asite.title1")}</div>
                <div
                  className={`description dynamic_line_height ${
                    currentLanguage === "en" ? "line_height_narrow" : ""
                  }`}
                >
                  {t("mmind.asite.description3")}
                  <a className="link_gray_dotted" href={termUrl}>
                    {t("mmind.asite.link_text3")}
                  </a>
                  {t("mmind.asite.description4")}
                </div>
              </div>
            </div>
            <div className="text_column" id="trademarks">
              <div className="inner">
                <div className="title">{t("mmind.asite.title2")}</div>
                <div
                  className={`description dynamic_line_height ${
                    currentLanguage === "en" ? "line_height_narrow" : ""
                  }`}
                >
                  <Trans i18nKey="mmind.asite.description5" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </Layout>
  );
}
